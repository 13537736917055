// extracted by mini-css-extract-plugin
export var awardCard = "cS_wf";
export var awardCard__bdg_large = "cS_wk";
export var awardCard__bdg_small = "cS_wl";
export var awardCard__container = "cS_wr";
export var awardCard__description = "cS_wq";
export var awardCard__info = "cS_wm";
export var awardCard__large = "cS_wh";
export var awardCard__link = "cS_wp";
export var awardCard__post = "cS_wg";
export var awardCard__small = "cS_wj";
export var awardCard__title = "cS_wn";